var carouselsjs = (function($){

  __$ = {
    pause: $('.carousel__pause'),
  };


  // variable to determined if (any) pause button listener has been started.
  // We only need one (which handles all pause buttons),
  // and we don't want duplicate events.
  var _pauseButtonListenerRunning = false;

  // object that will contain all instances of all swipers
  var imageSwiperConainer = {};


  var _changeCaptionCopy = function(id, target){
    var caption = $('#'+target+' [data-slidecaptioncounter="'+id+'"]').html();
    $('#captionfor'+target).html(caption);
  };



  /**
   * Function will initiate a swiper for each passed selector string
   * @param  {[string]} selectorstring  The jQuery selector string
   */
  var _swiperinit = function(selectorstring, passedspeed, passedEffect){

    var $parentElement = $(selectorstring).parents('.simplecarousel');

    // grabs and boolifies the autoplay setting from the data-attribute
    var autoplaysetting = $parentElement.attr('data-carautoplay') || 'true';
    if( autoplaysetting === 'true'){ autoplaysetting = true; }else{ autoplaysetting = false; }

    // grabs the carousel ID from the data-attribute
    var carousel_id = $(selectorstring).attr('data-carouselid');


    // creates a new entry into the swiperContainer object, containing all the swipers
    imageSwiperConainer[carousel_id] = new Swiper (selectorstring, {
      loop: true,
      speed: parseInt(passedspeed),
      autoplay: autoplaysetting,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },
      effect: passedEffect,
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.hohmncarousel-'+carousel_id+'-pagination',
        bulletElement: 'button',
        clickable: true,
      },
      navigation: {
        nextEl: '.hohmncarousel-'+carousel_id+'-next',
        prevEl: '.hohmncarousel-'+carousel_id+'-prev',
      },
      on: {
        slideChange: function () {
          // manually change the copy
          _changeCaptionCopy( this.realIndex, carousel_id );

          // this event is there in case we have a gallery isotope that needs to be re-layouted
          if( $parentElement.hasClass('gallerycarousel') ){
            $(window).trigger('galleryslidechanged');
          }
        }
      }
    }); // end `new Swiper` init

  }; // end _swiperinit


  /**
   * Handles all the pause buttons for all the swipers
   */
  var _pauseButtonHandler = function(){

    // if the pause button listener is not yet running,
    // start, and set that variable to true
    // ensures we only get one listener in caes of multiple carousels
    if( !_pauseButtonListenerRunning ){

      _pauseButtonListenerRunning = true;

      // when any of the pause buttons is clicked
      __$.pause.on('click', function(){

        // find which slider to address
        var pausebuttonfor = $(this).attr('data-pausebuttonfor');

        // and in playing state
        if( $(this).hasClass('this--playing') ){

          // pause the slider
           imageSwiperConainer[pausebuttonfor].autoplay.stop();
        }else{

          // it not, start the slider
          imageSwiperConainer[pausebuttonfor].autoplay.start();
        }

        // flip the classes
        $(this).toggleClass('this--playing');
        $(this).toggleClass('this--paused');

      });
    }
  };


  /**
   * PUBLIC METHOD
   * Init Method called in main.js.
   */
  var init = function(){

    // loops through all swipers and inits each one individually
    $('.hohmncarousel').each(function(){
      var curId = $(this).attr('id');
      var speed = $(this).attr('data-carouselspeed');
      var effct = $(this).attr('data-carouseleffect') || 'fade';
      //_swiperinit( '#'+curId , speed, effct);
    });

    // runs (checks) the pausebutton listner
    _pauseButtonHandler();
  };


 /**
  * Public Methods
  */
  return{
    init: init,
  };

})(jQuery);