var nav_scripts = (function($){
    var _addClassWhenScrolled = function(){
      $(window).on('scroll', function(){
        var classes_string = "this--scrolled";
        var $header = $("header.banner");
        if($(window).scrollTop() > $header.height()){
          if(!$header.hasClass(classes_string)){
            $header.addClass(classes_string);
          }
        }
        else{
          if($header.hasClass(classes_string)){
            $header.removeClass(classes_string);
          }
        }
      });
    };
    var _hamburger = function(){
      var $hamburger = $(".nav__trigger");
      var $nav_wrapper = $(".nav-primary");
      var $nav_wrapper_mobile_active_class = "nav-primary--show-mobile-menu";
      $hamburger.on("click", function(e){
        e.preventDefault();
        $(this).toggleClass("is-active");
        $nav_wrapper.toggleClass($nav_wrapper_mobile_active_class);
      });
    };
    var init = function(){
      _addClassWhenScrolled();
      _hamburger();
    };
    return {
      init : init,
    };
  })(jQuery);
  